import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/SEO"
import Layout from "../components/layout/layout"
import { Container, Baner } from "../components/utils/utils"
import { DownloadItem, Download } from "../components/download/download"
import Btn from "../components/button/Button"
import promocje2 from "../assets/promocje/Styczeń_kwiecień_2022.pdf"
import promocje1 from "../assets/promocje/maj_sierpien_2023.pdf"

const Promocje = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "main_image2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const main_image = data.bg.childImageSharp.fluid

  return (
    <div>
      <SEO title="Promocje" slug="/promocje" />
      <Layout>
        <Baner fluid={main_image} backgroundColor={`#FFFFFF`}>
          <div>
            <Container>
              <h1>Promocje</h1>
              <h2>Najnowsza gazetka promocyjna</h2>
            </Container>
          </div>
        </Baner>

        <Download>
          <h3>Do pobrania</h3>

          <p>
            Więcej informacji o aktualnych promocjach pod nr telefonu:{" "}
            <b> +48 570 133 366</b>
          </p>
          <p>
            Więcej informacji na temat aktualnych promocji znajduję się w
            gazetce promocyjnej. Zapraszamy do zapoznania się z aktualną ofertą.
            <br />
            Link znajduje się poniżej.
          </p>

          <DownloadItem>
            <div>
              <FontAwesomeIcon icon={faFilePdf} />
              <p>Gazetka promocyjna materiałów stomatologicznych 1</p>
            </div>
            <Btn href={promocje1} target="blank">
              Pobierz
            </Btn>
          </DownloadItem>

          {/* <DownloadItem>
            <div>
              <FontAwesomeIcon icon={faFilePdf} />
              <p>Gazetka promocyjna materiałów stomatologicznych 2</p>
            </div>
            <Btn href={promocje2} target="blank">
              Pobierz
            </Btn>
          </DownloadItem> */}
        </Download>
      </Layout>
    </div>
  )
}

export default Promocje
